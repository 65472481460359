import dayjs from 'dayjs'
import { League } from '@/GeneratedTypes/League'
export function setKeyDatesForNewLeague(league: League) {
  if (!league.upwardLeagueID) {
    const dt = dayjs().add(10, 'week')
    league.registrationBeginDate = dt.toDate()
    league.firstEvalDate = dt.add(1, 'week').toDate()
    league.firstPracticeDate = dt.add(2, 'week').toDate()
    league.firstGameDate = dt.add(3, 'week').toDate()
    league.awardsOrderDate = dt.add(1, 'month').toDate()
    league.seasonEndDate = dt.add(2, 'months').toDate()
  }
}
